@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --white: #ffffff;
    --primary: #6200BF;
    --secondary: #FF00F8;
    --dark: #0B0422;
    --footer: #1E1834;
    --blue: #0FA2DB;
    --bgrt: #17112D;
    --bgrb: #211B35;

    --cubic1 : cubic-bezier(.44,-0.5,.41,1.5);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

@font-face {
    font-family: "estedad";
    src: url("./fonts/estedad.woff2") format("woff2-variations");
    font-weight: 125 950;
    font-stretch: 75% 125%;
    font-style: normal;
}

.App {
  overflow-x: hidden;
}

.myAnimation1 {
  transition: all .5s var(--cubic1);
}

.introItem > .introItemLamp {
  background-color: var(--primary);
  right: 35%;
  left: 35%;
}

.introItem:hover > .introItemLamp {
  background-color: var(--secondary);
  box-shadow: var(--secondary) 0 0 4rem;
  right: 20%;
  left: 20%;
}

.introItem > div > .material-icons {
  color: var(--primary);
}

.introItem:hover > div > .material-icons {
  color: var(--secondary);
}

.introItem > div > h4 {
  color: var(--white);
}

.introItem:hover > div > h4 {
  color: var(--secondary);
  text-shadow: #FF00F8 0rem 0rem 2rem;
}


.home_plans_item {
	/* background-size: 400% 400%;
	animation: gradient1 15s ease infinite; */
  background-image: url('../public/images/plan_pattern.png');
  background-size: cover;
}


@keyframes gradient1 {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@keyframes gradient2 {
	0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

.onion_animation {
	animation: onionAnimation 30s linear 0s infinite alternate;
}

@keyframes onionAnimation {
  0% {
    transform: scale(1)
  }
  100% {
    transform: scale(4)
  }
}

.navlink {
  position: relative;
}

.navlink::before {
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  left: 100%;
  background: linear-gradient(-90deg, var(--primary), var(--secondary));
  height: .3rem;
  border-radius: .5rem;
  transition: all .5s var(--cubic1);
  opacity: 0;
}

.navlink:hover::before {
  opacity: 1;
  left: 0%;
}


.footer {
  background: linear-gradient(-45deg, var(--footer), var(--primary), var(--footer));
	background-size: 400% 400%;
	animation: gradient1 15s ease infinite alternate;
}